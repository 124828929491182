<template>
  <el-cascader
    filterable
    clearable
    v-model="dictValue"
    :options="dictOptions"
    :show-all-levels="false"
    :key="isRescourceShow"
    :props="{checkStrictly: getCheckStrictly, multiple : getMultiple, expandTrigger: 'hover'}"
    @change="dictChange"
    :disabled="readonly"></el-cascader>
</template>

<script>
  export default {
    name: 'dict-cascader',
    data () {
      return {
        // 当前选中的字典的父子id数组
        dictValue: [],
        // 所有字典的父子选项数组
        dictOptions: [],
        isRescourceShow: 0
      }
    },
    props: {
      /**
       * 初始的组织机构选定，需要从顶级父级id到选定机构id组成的数组
       * 如果不设置，则默认选定用户的最高权限
       */
      initDictValue: {
        type: Array
      },
      /**
       * 字典代码
       */
      code: {
        type: String,
        required: true
      },
      /**
       * 是否允许选择任一级别的字典
       * 默认为否
       */
      checkStrictly: {
        type: Boolean
      },
      /**
       * 是否允许多选
       * 默认为否
       */
      multiple: {
        type: Boolean
      },
      /**
       * 组织机构id
       */
      orgId: {
        type: Number
      },
      readonly: {
        type: Boolean
      },
      /**
       * 是否显示禁用
       * 默认为否
       */
      showDisabled: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      initDictValue: {
        immediate: true,
        handler (val) {
          if (this.initDictValue && this.dictOptions.length > 0 && this.initDictValue.length > 0) {
            this.dictValue = this.initDictValue
            let depth = this.dictValue.length
            let isDelete = true
            this.dictOptions.map(item => {
              if (item.value === this.dictValue[this.dictValue.length - 1]) {
                isDelete = false
              }
            })
            this.$emit('change', isDelete ? 0 : this.dictValue[this.dictValue.length - 1], depth, this.dictValue)
          }
        }
      },
      orgId: {
        immediate: true,
        handler (val) {
          if (val && val !== '' && val !== 0) {
            debugger
            this.getDicts()
            if (this.initDictValue && this.dictOptions.length > 0 && this.initDictValue.length > 0) {
              this.dictValue = this.initDictValue
              let depth = this.dictValue.length
              this.$emit('change', this.dictValue[this.dictValue.length - 1], depth, this.dictValue)
            }
          }
        }
      },
      showDisabled: {
        immediate: true,
        handler (val) {
          this.getDicts()
        }
      },
      dictOptions () {
        ++this.isRescourceShow
      }
    },
    computed: {
      getCheckStrictly () {
        if (this.checkStrictly === undefined) {
          return false
        } else {
          return this.checkStrictly
        }
      },
      getMultiple () {
        if (this.multiple === undefined) {
          return false
        } else {
          return this.multiple
        }
      }
    },
    created () {
      this.getDicts()
    },
    methods: {
      // 获取用户的树形权限机构
      getDicts () {
        if (this.orgId !== '' && this.orgId !== 0) {
          this.$nextTick(() => {
            this.$http({
              url: this.$http.adornUrl(`/sys/dict/listDictByCode`),
              method: 'get',
              params: this.$http.adornParams({
                'code': this.code,
                'orgId': this.orgId
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                let dicts = data.dicts
                if (!this.showDisabled) {
                  dicts = dicts.filter(dict => !dict.parameters.status)
                }
                this.dictOptions = dicts
                // 给机构赋初值，默认为第一个
                if (dicts.length > 0) {
                  if (this.initDictValue == null || this.initDictValue === undefined || this.initDictValue.length === 0) {
                    this.dictValue = [dicts[0].value]
                  } else {
                    this.dictValue = this.initDictValue
                  }
                  let depth = this.dictValue.length
                  this.$emit('change', this.dictValue[this.dictValue.length - 1], depth, this.dictValue)
                }
              }
            })
          })
        }
      },
      // 树形机构选择切换
      dictChange (value) {
        // 选择返回的是一个父子id的数组
        if (value.length > 0) {
          let depth = this.dictValue.length
          this.$emit('change', this.dictValue[this.dictValue.length - 1], depth, this.dictValue)
        } else {
          this.$emit('change', '', 0, this.dictValue)
        }
      },
      // 重置
      reset () {
        if (this.dictOptions.length > 0) {
          if (this.initDictValue == null || this.initDictValue === undefined || this.initDictValue.length === 0) {
            this.dictValue = [this.dictOptions[0].value]
          } else {
            this.dictValue = this.initDictValue
          }
          let depth = this.dictValue.length
          this.$emit('change', this.dictValue[this.dictValue.length - 1], depth, this.dictValue)
        }
      }
    }
  }
</script>

<style>
  .el-form-item .el-cascader {
    width: 100%;
  }
</style>
