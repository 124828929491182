<template>
  <div class="problem-info">
    <div class="form">
        <div class="title" :class="{'isOld36': isOld == 1}" v-if="!id">新增问题</div>
        <div class="title" :class="{'isOld36': isOld == 1}" v-else>问题详情</div>
        <div class="problem-item" :class="readonly ? 'disable' : ''" @click="typeShow = !typeShow">
          <div class="top">
            <div class="top-left" :class="{'isOld40': isOld == 1}">问题类别</div>
            <img class="top-right" :src="require('@/assets/img/right.png')" alt="">
          </div>
          <van-field class="down" :class="{'isOld40': isOld == 1}" readonly v-model="dataForm.typeStr" placeholder="请选择问题类别"></van-field>
        </div>
        <div class="problem-item" :class="readonly ? 'disable' : ''" v-if="dataForm.location == 2" @click="locationShow">
          <div class="top">
            <div class="top-left" :class="{'isOld40': isOld == 1}">所在位置</div>
            <img class="top-right" :src="require('@/assets/img/right.png')" alt="">
          </div>
          <van-field class="down" :class="{'isEmpty': !dataForm.loactionStr,'isOld40': isOld == 1}" autosize type="textarea" readonly v-model="dataForm.loactionStr" placeholder="请选择到楼幢或楼幢以下"></van-field>
        </div>
        <div class="problem-item" :class="readonly ? 'disable' : ''" @click="objShow = !objShow">
          <div class="top">
            <div class="top-left" :class="{'isOld40': isOld == 1}">上报对象</div>
            <img class="top-right" :src="require('@/assets/img/right.png')" alt="">
          </div>
          <van-field class="down" :class="{'isOld40': isOld == 1}" readonly v-model="dataForm.objStr"
                     placeholder="请选择指派对象"></van-field>
        </div>
        <div class="problem-detail">
          <div class="top" :class="{'isOld40': isOld == 1}">问题描述</div>
          <van-field type="textarea" :class="{'isOld40': isOld == 1}" :readonly="readonly" v-model="dataForm.des" placeholder="请输入问题描述" autosize maxlength="100" show-word-limit></van-field>
        </div>
        <div class="upload-picture" v-if="(fileList && fileList.length) || !id">
          <div class="top" :class="{'isOld40': isOld == 1}">上传图片</div>
          <upload-file
            :fileList="fileList"
            :uploadPath="uploadPath"
            @filesUpload="upload"
            @delFile="delFile"
            :delBtnShow="!readonly"
            :uploadBtnShow="!readonly"
          >
          </upload-file>
      </div>
    </div>
    <div class="button" v-if="!id">
      <van-button class="submit-btn" @click="onSubmit">提交</van-button>
    </div>
    <!-- 所在位置 -->
<!--    <house-select-->
<!--      ref="houseSelect"-->
<!--      @houseConfirm="houseConfirm"-->
<!--    >-->
<!--    </house-select>-->
    <!-- 问题类别 -->
<!--    <dict-->
<!--      :visible="typeShow"-->
<!--      :orgId="orgId"-->
<!--      :isAdd="true"-->
<!--      :multiple="false"-->
<!--      :code="'problemType'"-->
<!--      :title="'问题类别'"-->
<!--      @change="dictChange"-->
<!--    ></dict>-->
    <van-popup v-model="objShow" position="bottom">
      <van-picker title="指派对象" show-toolbar :columns="objList" value-key="label" @confirm="objConfirm"
                  @cancel="objShow = false"/>
    </van-popup>
  </div>
</template>

<script>
import dict from '@/components/dict/index'
import houseSelect from '../../components/houseSelect/index'
import uploadFile from '../../components/upload/uploadFile'
import {getImageStream} from '@/utils/index'
export default {
  data() {
    return {
      id: '',
      title: '',
      readonly: false,
      typeShow: false,
      objShow: false,
      objList: [
        {label: "社区", value: "2"}
      ],
      orgId: sessionStorage.getItem('orgId'),
      locationType: null,
      dataForm: {
        type: '',
        typeStr: '',
        objStr: '',
        des: '',
        location: '', // 1小区公共区域;2室内楼幢;100小区外
        loactionStr: '',
        subareaId: 0,
        subareaName: '',
        houseId: '',
        buildingId: '',
        fileMessages: []
      },
      fileList: [],
      uploadPath: 'files/problem/temporary',
      isOld: sessionStorage.getItem('isOld')
    }
  },
  components: {
    uploadFile,
    dict,
    houseSelect
  },
  created() {
    this.dataForm.location = parseInt(this.$route.query.type)
    if(this.$route.query.item) {
      this.readonly = true
      this.id = this.$route.query.item.id
      this.dataForm.typeStr = this.$route.query.item.problemTypeStr
      if(this.$route.query.item.subarea) {
        this.dataForm.location = 2
        this.title = '小区内问题详情'
      }else {
        this.dataForm.location = 1
        this.title = '小区外问题详情'
      }
      this.dataForm.des = this.$route.query.item.problemDes
      this.dataForm.loactionStr = this.$route.query.item.houseFullName
      this.dataForm.obj = this.$route.query.item.solverTypeNow
      this.dataForm.objStr = this.$route.query.item.solverTypeNowStr
      if(this.$route.query.item.fileMessages.length > 0) {
        this.fileList = this.$route.query.item.fileMessages.map(item => {
          return {
            fileType: item.fileType,
            id: item.id,
            relativePath: item.relativePath,
            url: getImageStream(item.relativePath)
          }
        })
      }
    }
  },
  methods: {
    locationShow() {
      this.$refs.houseSelect.visible = true
    },
     // 上传图片
    upload(res) {
      this.dataForm.fileMessages = res
    },
    delFile(files) {
      this.completeForm.removeIds.push(files)
    },
    objConfirm(val) {
      this.dataForm.objStr = val.label
      this.dataForm.obj = val.value
      this.objShow = false
    },
    onSubmit() {
      this.$toast.loading({ duration: 0, message: '提交中...', forbidClick: true, });
      if(!this.dataForm.type) return this.$toast.fail('问题类别不能为空')
      if(this.dataForm.location == 2 && !this.dataForm.subareaId) return this.$toast.fail('所在位置为空')
      if(this.dataForm.location == 2 && !this.dataForm.buildingId) return this.$toast.fail('位置请选择到楼幢或楼幢以下')
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/new/save'),
        method: 'POST',
        data: this.$http.adornData({
          orgId: this.orgId,
          loginOrgId: this.orgId,
          userId: sessionStorage.getItem('orgUserId'),
          placeType: this.dataForm.location,          //问题位置
          unitId: this.dataForm.unitId,                  // 单元
          subarea: this.dataForm.subareaId, //所在小区id
          buildingId: this.dataForm.buildingId || null, // 楼幢
          houseId: this.dataForm.houseId || null, // 房屋
          problemType: this.dataForm.type || null, //问题类型
          problemDes: this.dataForm.des || null,  // 问题描述
          newFileMessages: this.dataForm.fileMessages,
          solverTypeNow: this.dataForm.obj,
          community: sessionStorage.getItem('orgId')
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.$toast.success({
            message: '提交成功',
            duration: 3000,
          })
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500);
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    houseConfirm(value,label) {
      this.dataForm.unitId = ''
      this.dataForm.buildingId = ''
      this.dataForm.houseId = ''
      this.dataForm.loactionStr = ''
      label.forEach(item => {
        this.dataForm.loactionStr = this.dataForm.loactionStr + '-' + item.label
      })
      this.dataForm.loactionStr = this.dataForm.loactionStr.slice(1,this.dataForm.loactionStr.length)
      if(label.length == 1) {
        // 只选了小区
        this.dataForm.subareaId = label[0].id
      }else {
        if(label[1].divideHouse == 0 && label[1].divideUnit == 0) {
          // 独幢
          this.dataForm.subareaId = label[0].id
          this.dataForm.buildingId = label[1].value
        }else if(label[1].divideHouse == 1 && label[1].divideUnit == 0) {
          this.dataForm.subareaId = label[0].id
          this.dataForm.buildingId = label[1].value
        }else if(label[1].divideHouse == 1 && label[1].divideUnit == 1 && label.length == 2) {
          this.dataForm.subareaId = label[0].id
          this.dataForm.buildingId = label[1].value
        }else if(label[1].divideHouse == 1 && label[1].divideUnit == 1 && label.length == 3) {
          this.dataForm.subareaId = label[0].id
          this.dataForm.buildingId = label[1].value
          this.dataForm.unitId = label[label.length - 1].value
        }else if(label[label.length - 1].placeholder == '请输入室号') {
          this.dataForm.subareaId = label[0].id
          this.dataForm.buildingId = label[1].value
          this.dataForm.unitId = label[2].value
        } else {
          this.dataForm.subareaId = label[label.length - 1].subarea
          this.dataForm.unitId = label[label.length - 1].unitId
          this.dataForm.buildingId = label[label.length - 1].buildingId
          this.dataForm.houseId = label[label.length - 1].id
        }
      }
      if (this.dataForm.houseId) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/base/houseRes/live'),
          method: 'post',
          params: this.$http.adornParams({
            houseId: parseInt(this.dataForm.houseId)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            if (data.live) {
              this.$httpSync({
                url: this.$httpSync.adornUrl('/wxapp/user/resident/list'),
                method: 'post',
                params: this.$httpSync.adornParams({
                  page: 1,
                  limit: -1,
                  orgId: sessionStorage.getItem('orgId'),
                  houseId: this.dataForm.houseId
                })
              }).then(({data}) => {
                if (data && data.code === 0) {
                  let user = data.page.list.filter(i => i.userId == sessionStorage.getItem('userId'))[0]
                  if (user.relationship == 1) {
                    this.objList = [
                      {label: "社区", value: "2"}
                    ]
                  } else {
                    this.objList = [
                      {label: "户主", value: "1"},
                      {label: "社区", value: "2"}
                    ]
                  }
                }
              })
            }
          }
        })
      }
    },
    dictChange(value,label) {
      this.typeShow = false
      if(value) {
        this.dataForm.type = value.toString()
        this.dataForm.typeStr = label.toString()
      }
    },
  }
}
</script>

<style scoped>
.isOld40 >>> .van-cell {
  font-size: 40px !important;
}
</style>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
}
.problem-info {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: PingFangSC-Regular;
}
::v-deep .van-field__control {
  font-family: PingFangSC-Regular;
}
.title {
  height: 96px;
  background: #FFFFFF;
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  line-height: 96px;
  padding: 0 30px;
}
.problem-item {
  // height: 164px;
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  // padding: 0 30px;
  .top {
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    .top-left {
      font-size: 34px;
      font-weight: 400;
      color: #666666;
      span {
        color: #E9564B;
      }
    }
    .top-right {
      width: 28px;
      height: 28px;
    }
  }
  .down {
    // height: 76px;
    font-size: 34px;
    font-weight: 500;
    color: #333333;
  }
  ::v-deep .van-cell {
    padding: 0 30px;
  }
  ::v-deep .isEmpty textarea {
    height: 50px !important;
  }
}
.problem-detail {
  // height: 254px;
  background: #FFFFFF;
  padding-top: 24px;
  padding-bottom: 24px;
  .top {
    font-size: 34px;
    font-weight: 400;
    color: #666666;
    padding: 0 30px;
    margin-bottom: 12px;
  }
  .des {
    height: 96px;
    font-size: 34px;
    font-weight: 400;
    color: #333333;
  }
  ::v-deep .van-field__word-limit {
    color: #999999;
  }
  ::v-deep .van-cell {
    padding: 0 30px;
  }
}
.upload-picture {
  // min-height: 270px;
  background-color: #FFFFFF;
  padding: 0 30px;
  ::v-deep .van-cell {
    padding-top: 0;
    padding-left: 0;
  }
  .top {
    height: 88px;
    border-radius: 20px 20px 0px 0px;
    font-size: 26px;
    font-weight: 400;
    color: #666666;
    line-height: 88px;
  }
  ::v-deep .uploadBox {
    margin: 0;
  }
  ::v-deep .van-uploader__upload {
    background-color: #F5F5F5;
    border-radius: 16px;
  }
}
.button {
  padding: 0 30px;
  height: 88px;
  margin-bottom: 40px;
  .submit-btn {
    width: 100%;
    height: 88px;
    background: #4581F8;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
  }
}
</style>
